<template>
  <div class="SampleList">
    <!--    <van-nav-bar-->
    <!--      title=""-->
    <!--      left-text="清空表单"-->
    <!--      right-text="按钮"-->
    <!--    />-->
    <van-nav-bar
      @click-left="onClickLeft"
      left-text="返回"
      left-arrow
    />

    <van-search v-model="search" placeholder="请根据医院/患者/样本编号进行搜索"/>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-card
        v-for="sample in samples"
        :key="sample.id"
        tag="待检测"
        class="ing"
        :desc="sample.hospital || sample.cooperation"
        :title="'患者：' + sample.patient_name"
        :thumb="sample.img_url || default_img_url"
      >
        <template #tags>
          <van-tag plain type="warning">{{ sample.detect_type }}</van-tag>
          <!--        <van-tag plain type="danger">查看</van-tag>-->
        </template>
        <template #footer>
          <van-button size="small">反 馈</van-button>
          <van-button size="small" :to="{name:'SampleDisplay', query: {id:sample.id, record_id:sample.record_id}}">查 看
          </van-button>
        </template>
      </van-card>

      <!--      <van-card-->
      <!--        price="3000.00"-->
      <!--        tag="待检测"-->
      <!--        class="ing"-->
      <!--        desc="福建省南平第一医院"-->
      <!--        title="患者：倪乐潼"-->
      <!--        thumb="http://wx-api.knoindx.com/sample.jpg"-->
      <!--      >-->
      <!--        <template #tags>-->
      <!--          <van-tag plain type="warning">mNGS_DNA</van-tag>-->
      <!--          &lt;!&ndash;        <van-tag plain type="danger">查看</van-tag>&ndash;&gt;-->
      <!--        </template>-->
      <!--        <template #footer>-->
      <!--          <van-button size="small">反 馈</van-button>-->
      <!--          <van-button size="small" :to="{name:'SampleDisplay', query: {id:1}}">查 看</van-button>-->
      <!--        </template>-->
      <!--      </van-card>-->

      <!--      <van-card-->
      <!--        price="3000.00"-->
      <!--        tag="检测完成"-->
      <!--        class="done"-->
      <!--        desc="福建省南平第一医院"-->
      <!--        title="患者：倪乐潼"-->
      <!--        thumb="http://wx-api.knoindx.com/sample.jpg"-->
      <!--      >-->
      <!--        <template #tags>-->
      <!--          <van-tag plain type="warning">mNGS_DNA</van-tag>-->
      <!--          &lt;!&ndash;        <van-tag plain type="danger">查看</van-tag>&ndash;&gt;-->
      <!--        </template>-->
      <!--        <template #footer>-->
      <!--          <van-button plain size="small" type="primary">下载报告</van-button>-->
      <!--          <van-button size="small" :to="{name:'Feedback', query: {id:1}}">反{{ userInfo.openid }}馈</van-button>-->
      <!--          <van-button size="small" :to="{name:'SampleDisplay'}">查 看</van-button>-->
      <!--        </template>-->
      <!--      </van-card>-->
    </van-pull-refresh>

  </div>

</template>

<script>
import { Toast } from 'vant'
import { getSamplesApi } from '@/api/sample'
import { DEFAULT_IMG_URL } from '@/const'

export default {
  name: 'SampleList',
  metaInfo: {
    title: '送检清单1',
  },
  created() {
    this.initUser()
    if (this.userInfo.openid) {
      this.getSamples({ openid: this.userInfo.openid })
    }
  },
  data() {
    return {
      samples: [],
      isLoading: false,
      search: ''
    }
  },
  methods: {
    onRefresh() {
      setTimeout(() => {
        this.getSamples({ openid: this.userInfo.openid })
        Toast('刷新成功')
        this.isLoading = false
        this.count++
      }, 1000)
    },
    getSamples(params) {
      getSamplesApi(params).then(res => {
        this.samples = res.data.results
      })
    },
  }
}
</script>

<style scoped>
/*以下是样本状态标签颜色*/
.ing.van-card >>> .van-card__tag > span {
  background-color: #ff976a;
}

.done.van-card >>> .van-card__tag > span {
  background-color: #00a29a;
}
</style>
